import React from "react";
import { graphql } from "gatsby";

import { useTranslations } from "../hooks/translations";
import { useElectionOver } from "../hooks/election-over";
import SEO from "../components/seo";
import RegistrationForm from "../components/volunteering/registration-form";
import AboutVolunteering from "../components/volunteering/about-volunteering";
import RegistrationSuspended from "../components/volunteering/registration-suspended";

export const query = graphql`
  query AboutVolunteeringQuery($locale: String!) {
    translations(locale: $locale) {
      pages {
        volunteering
      }
      enums {
        municipality
      }
      form
      registrationForm
      aboutVolunteering
      recaptcha
      ...LayoutTranslations
    }
  }
`;

const AboutVolunteeringPage = () => {
  const t = useTranslations("pages.volunteering");

  const isElectionOver = useElectionOver();

  return (
    <>
      <SEO title={t.title} description={t.metaDescription} />

      <AboutVolunteering />

      {isElectionOver ? <RegistrationSuspended /> : <RegistrationForm />}
    </>
  );
};

export default AboutVolunteeringPage;
